<template>
    <v-card
        class="mx-auto mt-6"
        max-width="700"
    >
        <v-card>
            <v-card-text>Usted se encuentra: <strong>Creando una nueva Sucursal</strong></v-card-text>
        </v-card>
        <v-stepper alt-labels v-model="e1">
            <v-stepper-header class="d-flex justify-center">
            <v-stepper-step
                :rules="[() => stepOne]"
                editable
                step="1"
                :complete="e1 > 1"
                @click="validateOne = true"
            >
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                <One @valid="validStep" :validate="validateOne"></One>

                <v-btn
                color="primary"
                @click="receiveData"
                :disabled="invalidForm"
                >
                Crear Sucursal
                </v-btn>

                <v-btn @click="rollbackLink" text>
                Cancelar
                </v-btn>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import Swal from "sweetalert2";

import One from "./form/1.vue";

import Storage from "@/modules/storage";

const store = new Storage();

  export default {
    data () {
      return {
        data: {},
        e1: 1,
        invalidForm: true,
        stepOne: false,
        validateOne: false
      }
    },
    components: {
        One
    },
    methods: {
        validStep(value) {
            if (value.step === 1) {
                this.stepOne = value.valid;
            }
            for (let [key, value] of Object.entries(value.data)) {
                this.data[key] = value;
            }
            if (this.stepOne) {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        },
        async receiveData() {
            this.validateOne = true;
            Swal.fire({
                icon: "warning",
                title: "Creando Sucursal",
                html:
                "Por favor espere mientras se crea la Sucursal</b> " +
                '<progress id="progressBar" value="0" max="100"></progress>',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
            });
            try {
                await store.insertData("misalud", "branch", [this.data]);
                Swal.fire({
                icon: "success",
                title: "Sucursal creada"
                }).then(() => {
                    this.$router.go(-1);
                });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error al crear la Sucursal"
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        rollbackLink() {
            Swal.fire({
                title: "¿Abandonar el Formulario?",
                showCancelButton: true,
                confirmButtonText: `Abandonar`,
                cancelButtonText: "Quedarse"
            }).then(result => {
                if (result.isConfirmed) {
                this.$router.go(-1);
                }
            });
        }
    },
  }
</script>